var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saml-auth-link"},[_c('ABButton',{on:{"click":function($event){$event.preventDefault();return _vm.onClick()}}},[_vm._v("\n    "+_vm._s(_vm.buttonLabel)+"\n  ")]),_vm._v(" "),_c('Modal',{ref:"modal"},[_c('ThemeProvider',[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.hasMultipleSamlProvider)?_c('ABFormGroup',{attrs:{"label":_vm.$t('samlAuthLink.provideEmail'),"error-message":_vm.$v.values.email.$dirty
              ? !_vm.$v.values.email.required
                ? _vm.$t('error.requiredField')
                : !_vm.$v.values.email.email
                ? _vm.$t('error.invalidEmail')
                : ''
              : '',"autocomplete":_vm.isEditMode ? 'off' : '',"required":""}},[_c('ABInput',{attrs:{"placeholder":_vm.$t('samlAuthLink.emailPlaceholder')},on:{"blur":function($event){return _vm.$v.values.email.$touch()}},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", $$v)},expression:"values.email"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"saml-auth-link__modal-footer"},[_c('ABButton',{staticClass:"margin-top-2",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.login()}}},[_vm._v("\n            "+_vm._s(_vm.buttonLabel)+"\n          ")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }