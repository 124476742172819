var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('gitlab_url'),"helper-text":_vm.$t('gitlabIssuesDataSync.urlHelper'),"required":"","small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('gitlabIssuesDataSync.url')))]},proxy:true},{key:"error",fn:function(){return [(_vm.$v.values.gitlab_url.$dirty && !_vm.$v.values.gitlab_url.required)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):(_vm.$v.values.gitlab_url.$dirty && !_vm.$v.values.gitlab_url.url)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.invalidURL'))+"\n      ")]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('FormInput',{ref:"gitlab_url",attrs:{"size":"large","error":_vm.fieldHasErrors('gitlab_url'),"disabled":_vm.disabled},on:{"~focus":function($event){return $event.target.select()},"blur":function($event){return _vm.$v.values.gitlab_url.$touch()}},model:{value:(_vm.values.gitlab_url),callback:function ($$v) {_vm.$set(_vm.values, "gitlab_url", $$v)},expression:"values.gitlab_url"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('gitlab_project_id'),"label":_vm.$t('gitlabIssuesDataSync.projectId'),"required":"","helper-text":_vm.$t('gitlabIssuesDataSync.projectIdHelper'),"small-label":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.gitlab_project_id.$dirty &&
          !_vm.$v.values.gitlab_project_id.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.fieldHasErrors('gitlab_project_id'),"disabled":_vm.disabled,"size":"large"},on:{"blur":function($event){return _vm.$v.values.gitlab_project_id.$touch()}},model:{value:(_vm.values.gitlab_project_id),callback:function ($$v) {_vm.$set(_vm.values, "gitlab_project_id", $$v)},expression:"values.gitlab_project_id"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('gitlab_access_token'),"label":_vm.$t('gitlabIssuesDataSync.accessToken'),"required":"","helper-text":_vm.$t('gitlabIssuesDataSync.accessTokenHelper'),"small-label":"","protected-edit":_vm.update},on:{"enabled-protected-edit":function($event){return _vm.allowedValues.push('gitlab_access_token')},"disable-protected-edit":function($event){;[
        _vm.allowedValues.splice(_vm.allowedValues.indexOf('gitlab_access_token'), 1),
        delete _vm.values['gitlab_access_token'],
      ]}},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.gitlab_access_token.$dirty &&
          !_vm.$v.values.gitlab_access_token.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.fieldHasErrors('gitlab_access_token'),"disabled":_vm.disabled,"size":"large"},on:{"blur":function($event){return _vm.$v.values.gitlab_access_token.$touch()}},model:{value:(_vm.values.gitlab_access_token),callback:function ($$v) {_vm.$set(_vm.values, "gitlab_access_token", $$v)},expression:"values.gitlab_access_token"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }