var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.open || _vm.keepContent)?_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.keepContent && _vm.open) || !_vm.keepContent),expression:"(keepContent && open) || !keepContent"}],ref:"modalWrapper",staticClass:"modal__wrapper",on:{"click":function($event){return _vm.outside($event)}}},[_c('div',{staticClass:"modal__box",class:{
      'modal__box--full-height': _vm.fullHeight,
      'modal__box--full-max-height': !_vm.fullHeight && _vm.contentScrollable,
      'modal__box--with-sidebar': _vm.sidebar,
      'modal__box--full-screen': _vm.fullScreen,
      'modal__box--wide': _vm.wide,
      'modal__box--small': _vm.small,
      'modal__box--tiny': _vm.tiny,
      'modal__box--right': _vm.right,
    }},[(_vm.sidebar)?[(_vm.leftSidebar)?_c('div',{staticClass:"modal__box-sidebar modal__box-sidebar--left",class:{ 'modal__box-sidebar--scrollable': _vm.leftSidebarScrollable }},[_vm._t("sidebar")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__box-content",class:{
          'modal__box-content--scrollable': _vm.contentScrollable,
          'modal__box-content-no-padding': !_vm.contentPadding,
        }},[_vm._t("content"),_vm._v(" "),_c('div',{staticClass:"modal__actions"},[(_vm.closeButton && _vm.canClose)?_c('a',{staticClass:"modal__close",attrs:{"role":"button","title":_vm.$t('action.close')},on:{"click":function($event){return _vm.hide()}}},[_c('i',{staticClass:"iconoir-cancel"})]):_vm._e(),_vm._v(" "),(_vm.collapsibleRightSidebar)?_c('a',{staticClass:"modal__collapse",on:{"click":_vm.collapseSidebar}},[_c('i',{class:{
                'iconoir-fast-arrow-right': !_vm.sidebarCollapsed,
                'iconoir-fast-arrow-left': _vm.sidebarCollapsed,
              }})]):_vm._e(),_vm._v(" "),_vm._t("actions")],2)],2),_vm._v(" "),(_vm.rightSidebar)?_c('div',{staticClass:"modal__box-sidebar modal__box-sidebar--right",class:{
          'modal__box-sidebar--scrollable': _vm.rightSidebarScrollable,
          'modal__box-sidebar--collapsed': _vm.sidebarCollapsed,
        }},[(!_vm.sidebarCollapsed)?_vm._t("sidebar"):_vm._e()],2):_vm._e()]:_vm._e(),_vm._v(" "),(!_vm.sidebar)?[_vm._t("default"),_vm._v(" "),_vm._t("content"),_vm._v(" "),_c('div',{staticClass:"modal__actions"},[(_vm.closeButton && _vm.canClose)?_c('a',{staticClass:"modal__close",on:{"click":function($event){return _vm.hide()}}},[_c('i',{staticClass:"iconoir-cancel"})]):_vm._e(),_vm._v(" "),_vm._t("actions")],2)]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }