var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('jira_url'),"helper-text":_vm.$t('jiraIssuesDataSync.urlHelper'),"required":"","small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('jiraIssuesDataSync.jiraUrl')))]},proxy:true},{key:"error",fn:function(){return [(_vm.$v.values.jira_url.$dirty && !_vm.$v.values.jira_url.required)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):(_vm.$v.values.jira_url.$dirty && !_vm.$v.values.jira_url.url)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.invalidURL'))+"\n      ")]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('FormInput',{ref:"jira_url",attrs:{"size":"large","error":_vm.fieldHasErrors('jira_url'),"disabled":_vm.disabled},on:{"~focus":function($event){return $event.target.select()},"blur":function($event){return _vm.$v.values.jira_url.$touch()}},model:{value:(_vm.values.jira_url),callback:function ($$v) {_vm.$set(_vm.values, "jira_url", $$v)},expression:"values.jira_url"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('jira_username'),"helper-text":_vm.$t('jiraIssuesDataSync.usernameHelper'),"required":"","small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('jiraIssuesDataSync.jiraUsername')))]},proxy:true},{key:"error",fn:function(){return [(
          _vm.$v.values.jira_username.$dirty && !_vm.$v.values.jira_username.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('FormInput',{ref:"jira_username",attrs:{"size":"large","error":_vm.fieldHasErrors('jira_username'),"disabled":_vm.disabled},on:{"~focus":function($event){return $event.target.select()},"blur":function($event){return _vm.$v.values.jira_username.$touch()}},model:{value:(_vm.values.jira_username),callback:function ($$v) {_vm.$set(_vm.values, "jira_username", $$v)},expression:"values.jira_username"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('jira_api_token'),"helper-text":_vm.$t('jiraIssuesDataSync.apiTokenHelper'),"required":"","small-label":"","protected-edit":_vm.update},on:{"enabled-protected-edit":function($event){return _vm.allowedValues.push('jira_api_token')},"disable-protected-edit":function($event){;[
        _vm.allowedValues.splice(_vm.allowedValues.indexOf('jira_api_token'), 1),
        delete _vm.values['jira_api_token'],
      ]}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('jiraIssuesDataSync.apiToken')))]},proxy:true},{key:"error",fn:function(){return [(
          _vm.$v.values.jira_api_token.$dirty &&
          !_vm.$v.values.jira_api_token.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('FormInput',{ref:"jira_api_token",attrs:{"size":"large","error":_vm.fieldHasErrors('jira_api_token'),"disabled":_vm.disabled},on:{"~focus":function($event){return $event.target.select()},"blur":function($event){return _vm.$v.values.jira_api_token.$touch()}},model:{value:(_vm.values.jira_api_token),callback:function ($$v) {_vm.$set(_vm.values, "jira_api_token", $$v)},expression:"values.jira_api_token"}})],1),_vm._v(" "),_c('FormGroup',{attrs:{"helper-text":_vm.$t('jiraIssuesDataSync.projectKeyHelper'),"small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('jiraIssuesDataSync.projectKey')))]},proxy:true}])},[_vm._v(" "),_c('FormInput',{ref:"jira_project_key",attrs:{"size":"large","disabled":_vm.disabled},on:{"~focus":function($event){return $event.target.select()}},model:{value:(_vm.values.jira_project_key),callback:function ($$v) {_vm.$set(_vm.values, "jira_project_key", $$v)},expression:"values.jira_project_key"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }