var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('github_issues_owner'),"label":_vm.$t('githubIssuesDataSync.owner'),"required":"","helper-text":_vm.$t('githubIssuesDataSync.ownerHelper'),"small-label":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.github_issues_owner.$dirty &&
          !_vm.$v.values.github_issues_owner.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.fieldHasErrors('github_issues_owner'),"disabled":_vm.disabled,"size":"large"},on:{"blur":function($event){return _vm.$v.values.github_issues_owner.$touch()}},model:{value:(_vm.values.github_issues_owner),callback:function ($$v) {_vm.$set(_vm.values, "github_issues_owner", $$v)},expression:"values.github_issues_owner"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('github_issues_repo'),"label":_vm.$t('githubIssuesDataSync.repo'),"required":"","helper-text":_vm.$t('githubIssuesDataSync.repoHelper'),"small-label":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.github_issues_owner.$dirty &&
          !_vm.$v.values.github_issues_owner.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.fieldHasErrors('github_issues_repo'),"disabled":_vm.disabled,"size":"large"},on:{"blur":function($event){return _vm.$v.values.github_issues_repo.$touch()}},model:{value:(_vm.values.github_issues_repo),callback:function ($$v) {_vm.$set(_vm.values, "github_issues_repo", $$v)},expression:"values.github_issues_repo"}})],1),_vm._v(" "),_c('FormGroup',{attrs:{"error":_vm.fieldHasErrors('github_issues_api_token'),"label":_vm.$t('githubIssuesDataSync.apiToken'),"required":"","helper-text":_vm.$t('githubIssuesDataSync.apiTokenHelper'),"small-label":"","protected-edit":_vm.update},on:{"enabled-protected-edit":function($event){return _vm.allowedValues.push('github_issues_api_token')},"disable-protected-edit":function($event){;[
        _vm.allowedValues.splice(
          _vm.allowedValues.indexOf('github_issues_api_token'),
          1
        ),
        delete _vm.values['github_issues_api_token'],
      ]}},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.github_issues_api_token.$dirty &&
          !_vm.$v.values.github_issues_api_token.required
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.fieldHasErrors('github_issues_api_token'),"disabled":_vm.disabled,"size":"large"},on:{"blur":function($event){return _vm.$v.values.github_issues_api_token.$touch()}},model:{value:(_vm.values.github_issues_api_token),callback:function ($$v) {_vm.$set(_vm.values, "github_issues_api_token", $$v)},expression:"values.github_issues_api_token"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }