var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('p',{staticClass:"margin-bottom-2 margin-top-3"},[_vm._v("\n    "+_vm._s(_vm.$t('postgreSQLDataSync.description'))+"\n  ")]),_vm._v(" "),_vm._l(([
      { name: 'postgresql_host', translationPrefix: 'host', type: 'text' },
      {
        name: 'postgresql_username',
        translationPrefix: 'username',
        type: 'text',
      },
      {
        name: 'postgresql_password',
        translationPrefix: 'password',
        type: 'password',
        protectedEdit: true,
      },
      {
        name: 'postgresql_database',
        translationPrefix: 'database',
        type: 'text',
      },
      {
        name: 'postgresql_schema',
        translationPrefix: 'schema',
        type: 'text',
      },
      { name: 'postgresql_table', translationPrefix: 'table', type: 'text' },
    ]),function(field){return _c('FormGroup',{key:field.name,staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors(field.name),"required":"","small-label":"","protected-edit":_vm.update && field.protectedEdit},on:{"enabled-protected-edit":function($event){return _vm.allowedValues.push(field.name)},"disable-protected-edit":function($event){;[
        _vm.allowedValues.splice(_vm.allowedValues.indexOf(field.name), 1),
        delete _vm.values[field.name],
      ]}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t(`postgreSQLDataSync.${field.translationPrefix}`)))]},proxy:true},{key:"error",fn:function(){return [(_vm.$v.values[field.name].$dirty && !_vm.$v.values[field.name].required)?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "),_c('FormInput',{attrs:{"size":"large","type":field.type,"error":_vm.fieldHasErrors(field.name),"disabled":_vm.disabled},on:{"blur":function($event){_vm.$v.values[field.name].$touch()}},model:{value:(_vm.values[field.name]),callback:function ($$v) {_vm.$set(_vm.values, field.name, $$v)},expression:"values[field.name]"}})],1)}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-5"},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"error":_vm.fieldHasErrors('postgresql_port'),"required":"","small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('postgreSQLDataSync.port')))]},proxy:true},{key:"error",fn:function(){return [(
              _vm.$v.values.postgresql_port.$dirty &&
              !_vm.$v.values.postgresql_port.required
            )?_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('error.requiredField'))+"\n          ")]):(
              _vm.$v.values.postgresql_port.$dirty &&
              !_vm.$v.values.postgresql_port.numeric
            )?_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('error.invalidNumber'))+"\n          ")]):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('FormInput',{attrs:{"size":"large","error":_vm.fieldHasErrors('postgresql_port'),"disabled":_vm.disabled},on:{"blur":function($event){return _vm.$v.values.postgresql_port.$touch()}},model:{value:(_vm.values.postgresql_port),callback:function ($$v) {_vm.$set(_vm.values, "postgresql_port", $$v)},expression:"values.postgresql_port"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col col-7"},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"required":"","small-label":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('postgreSQLDataSync.sslMode')))]},proxy:true}])},[_vm._v(" "),_c('Dropdown',{attrs:{"size":"large","disabled":_vm.disabled},model:{value:(_vm.values.postgresql_sslmode),callback:function ($$v) {_vm.$set(_vm.values, "postgresql_sslmode", $$v)},expression:"values.postgresql_sslmode"}},_vm._l((_vm.sslModeOptions),function(option){return _c('DropdownItem',{key:option,attrs:{"name":option,"value":option}})}),1)],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }